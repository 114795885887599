import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Banner from 'common/Banner/Banner';
import { getHexColor } from 'utils/umbracoControlsUtils';
import { toBoolean } from 'utils/stringUtils/stringUtils';

const SignUpBanner = ({ lang: pageLang }) => {
  const { allSignUpBanner }: HomepageTypes.HomepageType = useStaticQuery(graphql`
    {
      allSignUpBanner {
        nodes {
          lang
          content
          bannerVariant
          withWrapper
          backgroundColor {
            value
          }
          button {
            ariaLabel
            text
            link {
              url
            }
          }
        }
      }
    }
  `);

  const {
    content,
    bannerVariant,
    backgroundColor,
    withWrapper,
    button,
  } = allSignUpBanner.nodes.filter(({ lang }) => lang === pageLang)[0];

  return content || button ? (
    <Banner
      {...{
        content,
        bannerVariant,
        button,
        backgroundColor: getHexColor(backgroundColor),
        isWithWrapper: toBoolean(withWrapper),
      }}
    />
  ) : null;
};

export default SignUpBanner;
